.red{
    background: #ff6666;
}
.secondtable thead {
    display: none;
}

.grey:hover{
    background: #C0C0C0 !important;
}

.delete-icon-style{
    font-size: 20px;
    cursor: pointer;
    color: red;
}

.ant-tooltip-inner {
    width: max-content;
}

.red>.ant-table-column-sort {
   background: #ff6666 !important;
}

.ant-modal {
    max-width: unset;
  }
.ant-modal-centered::before {
    content: unset;
}

.ant-table-footer {
    display: none;
}