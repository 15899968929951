.stepbar {
  background-color: #fff;
  height: 64px;
  display: flex;
  font-family: verdana;
  justify-content: center;
}

.wrapper {
  width: 8%;
  display: flex;
  position: relative;
  align-items: stretch;
  margin-left: -14px;
  text-align: center;
}

.arrow {
  width: 0px;
  height: 0px;
}

.start {
  border-top: 32px solid lightblue;
  border-left: 16px solid transparent;
  border-bottom: 32px solid lightblue;
}

.end {
  border-top: 32px solid transparent;
  border-left: 16px solid lightblue;
  border-bottom: 32px solid transparent;
}

.middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: lightblue;
  justify-content: center;  
}

.wrapper .text {
  align-self: center;
  color: black;
  font-size: 13px;
}
.active .text {
  align-self: center;
  color: white;
  padding: 5px;
  font-size: 13px;
}

.wrapper:first-of-type {
  margin-left: 0;
}

.wrapper:first-of-type .start {
  border-color: lightblue;
}

.wrapper.active .start {
  border-top-color: #2269c3;
  border-bottom-color: #2269c3;
}

.wrapper.active .middle {
  color: white;
  background-color: #2269c3;
}

.wrapper.active .end {
  border-left-color: #2269c3;
}

.wrapper.active:first-of-type .start {
  border-color: #2269c3;
}

.wrapper.active .text {
  visibility: visible;
}

.wrapper.greyrald .middle{
  background-color: gray;
}

.wrapper.greyrald .end {
  border-left-color: gray;
}

.wrapper.greyrald .start {
  border-top-color: gray;
  border-bottom-color: gray;
}

.wrapper.greyrald:first-of-type .start {
  border-color: grey;
}
